import { put } from 'redux-saga/effects';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { setList, setItem } from '../actions/serviceDistributions';
import { addError } from '../actions/errors';
import { history } from '../../store';
import { routes } from '../../routes';

export function* loadServiceDistributionsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.spdn.list(payload);
    yield put(setList({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadServiceDistributionItemSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.spdn.item(payload.id);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
    payload.onFinish(false);
  } catch (err) {
    yield put(setIsLoading(false));
    payload.onFinish(true);
    yield put(addError(err));
  }
}

export function* createServiceDistributionsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.spdn.create(payload);
    yield put(setIsLoading(false));
    history.push(routes.serviceDistributions());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* updateServiceDistributionsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.spdn.update(payload);
    yield put(setIsLoading(false));
    history.push(routes.serviceDistributions());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}
