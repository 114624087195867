import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import settingsReducer from './settings';
import ordersReducer from './orders';
import rejectionsReducer from './rejections';
import markingReducer from './marking';
import aggregationReducer from './aggregation';
import validationReducer from './validation';
import currentUserReducer from './currentUser';
import devicesReducer from './devices';
import stickersReducer from './stickers';
import reportsReducer from './reports';
import authReducer from './auth';
import errorsReducer from './errors';
import participantsReducer from './participants';
import serviceProvidesReducer from './serviceProvides';
import serviceDistributionsReducer from './serviceDistributions';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  devices: devicesReducer,
  settings: settingsReducer,
  aggregation: aggregationReducer,
  validation: validationReducer,
  orders: ordersReducer,
  rejections: rejectionsReducer,
  marking: markingReducer,
  currentUser: currentUserReducer,
  reports: reportsReducer,
  errors: errorsReducer,
  stickers: stickersReducer,
  participants: participantsReducer,
  serviceProvider: serviceProvidesReducer,
  serviceDistributions: serviceDistributionsReducer,
});
