import React from 'react';
import LogoKZBig from '../../assets/logos/logoBigKZ.jpg';
import LogoKZSmall from '../../assets/logos/logoSmallKZ.jpg';
import LogoAM from '../../assets/logos/logoAM.png';
import LogoSmallAM from '../../assets/logos/logoSmallAM.png';
import LogoKGBig from '../../assets/logos/logo.png';
import LogoKGSmall from '../../assets/logos/logo_short.png';
import LogoRU from '../../assets/logos/logo_ru.svg';
import LogoRUSmall from '../../assets/logos/ru_small.svg';
import LogoRuSvg from '../../assets/logos/UZ/Logo2_ru-01-01.svg';
import LogoUzSvg from '../../assets/logos/UZ/Logo1_uz-01-01.svg';
import LogoENSvg from '../../assets/logos/UZ/EN/Logo_EN-02.svg';
import LogoUz from '../../assets/logos/UZ/Logo_uz_small.png';

export const Logo = ({ country, currentLanguage }) => {
  switch (country) {
    case 'KZ': return (<img src={LogoKZBig} alt="LogoKZBig" />);
    case 'AM': return (<img src={LogoAM} alt="LogoAM" />);
    case 'KG': return (<img src={LogoKGBig} alt="LogoKGBig" />);
    case 'RU': return (<img src={LogoRU} alt="LogoRU" />);
    case 'UZ':
      switch (currentLanguage) {
        case 'UZ':
          return (<img src={LogoUzSvg} alt="LogoUzSvg" />);
        case 'EN':
          return (<img src={LogoENSvg} alt="LogoENSvg" />);
        default:
          return (<img src={LogoRuSvg} alt="LogoRuSvg" />);
      }
    default: return (<img src={LogoRU} alt="LogoRU" />);
  }
};

export const LogoShort = ({ country }) => {
  switch (country) {
    case 'KZ': return (<img src={LogoKZSmall} alt="" />);
    case 'AM': return (<img src={LogoSmallAM} alt="" />);
    case 'KG': return (<img src={LogoKGSmall} alt="" />);
    case 'RU': return (<img src={LogoRUSmall} alt="" />);
    case 'UZ': return (<img className="logoUzSmall" src={LogoUz} alt="" />);
    default: return (<img src={LogoRUSmall} alt="" />);
  }
};
