/* eslint-disable */
import React, { useEffect, useState } from 'react';
import fp from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../components/spinner';
import LoginPage from './LoginPage';
import { check } from '../../store/actions/auth';
import { getProfileData } from '../../store/actions/participants';
import {getCookie, setCookie} from "../../utils/utils";

const Auth = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { i18n } = useTranslation();
  const dsp = useDispatch();
  const auth = useSelector(((state) => state.auth));

  useEffect(() => {
    const language = getCookie('language') || 'RU'
    if (!language) {
      setCookie('language', 'RU');
      i18n.changeLanguage('RU');
    } else {
      i18n.changeLanguage(language);
    }
    dsp(check({
      onFinish: (isNeedLogin) => {
        if (!isNeedLogin) {
          setTimeout(() => {dsp(getProfileData());}, 300)
        }
        setIsLoading(false);
      },
    }));
  }, []);
  // return <>{children}</>;
  return (
    // eslint-disable-next-line no-nested-ternary
    fp.isEmpty(auth) || isLoading
      ? <div className="spinnerWrap"><Spinner /></div>
      : (auth.isNotAuth)
        ? <LoginPage />
        : children
  );
};

export default Auth;
