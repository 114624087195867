import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 150,
    marginRight: 10,
  },
  buttonContained: {
    color: theme.palette.primary.iconColorHover,
  },
  buttonOutlined: {
    color: theme.palette.text.primary,
  },
}));
