import { get } from 'lodash';
import types from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';

const initialState = {
  list: [],
  creationList: [],
  item: {},
  isError: false,
  totalElements: 0,
  filter: {},
  pagination: { page: 1, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SP_SET_LIST:
      return {
        ...state,
        list: parseServices(action.payload),
      };
    case types.SP_SET_LIST_FOR_CONNECTION:
      return {
        ...state,
        creationList: action.payload?.services || [],
      };
    case types.SET_SP:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_SP_CONNECTIONS:
      return {
        ...state,
        item: {},
        creationList: [],
      };
    default:
      return state;
  }
};

export default reducer;

const parseServices = (data) => {
  const list = get(data, 'services', []);
  return list.map((item) => ({
    ...item,
    pgNames: item.productGroups.map((pg) => PRODUCT_GROUPS_NAMES[pg]),
  }));
};
