import { createAction } from '../../helpers/redux';
import types from '../types';

export const set = createAction(types.SET_MARKING);
export const getList = createAction(types.MARKING_GET_LIST);
export const setList = createAction(types.MARKING_SET_LIST);
export const getItem = createAction(types.MARKING_GET_ITEM);
export const setItem = createAction(types.MARKING_SET_ITEM);
export const create = createAction(types.CREATE_MARKING);
export const update = createAction(types.UPDATE_MARKING);
export const clear = createAction(types.CLEAR_MARKING);
export const setError = createAction(types.MARKING_SET_ITEM_ERROR);
