import { createAction } from '../../helpers/redux';
import types from '../types';

export const set = createAction(types.SET_REJECTION);
export const getList = createAction(types.REJECTION_GET_LIST);
export const setList = createAction(types.REJECTION_SET_LIST);
export const getItem = createAction(types.REJECTION_GET_ITEM);
export const setItem = createAction(types.REJECTION_SET_ITEM);
export const create = createAction(types.CREATE_REJECTION);
export const clear = createAction(types.CLEAR_REJECTION);
export const update = createAction(types.UPDATE_REJECTION);
