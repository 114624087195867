import { get } from 'lodash';
import moment from 'moment';
import types from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import { DROPOUT_STATUSES } from '../../pages/marking/constants';
import { DROPOUT_REASON_NAMES } from '../../pages/dropout/constants';
import i18n from '../../i18n';

const initialState = {
  list: [],
  item: {},
  totalElements: 0,
  filter: {},
  pagination: { page: 0, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REJECTION_SET_LIST:
      return {
        ...state,
        totalElements: action.payload.totalElements,
        list: action.payload.content,
      };
    case types.REJECTION_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.SET_REJECTION:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_REJECTION:
      return {
        ...state,
        item: {},
      };
    default:
      return state;
  }
};

const parseItem = (data) => ({
  ...data,
  pgName: i18n.t(PRODUCT_GROUPS_NAMES[data.pg]),
  statusName: i18n.t(DROPOUT_STATUSES[data.status]),
  rejectReason: data.rejectReason,
  products: get(data, 'json.sntins', []).map((item) => ({ code: item })),
  address: get(data, 'json.address', '—'),
  dropoutReason: get(data, 'json.dropoutReason', '—'),
  dropoutReasonName: data.json.dropoutReason ? i18n.t(DROPOUT_REASON_NAMES[data.json.dropoutReason]) : '—',
  participantId: get(data, 'json.participantId', '—'),
  productLineId: get(data, 'json.productLineId', '—'),
  productOrderId: get(data, 'json.productOrderId', '—'),
  sourceDocNum: get(data, 'json.sourceDocNum', '—'),
  withChild: i18n.t(data.json.withChild ? 'Да' : 'Нет'),
  createdTimestamp: moment(data.createdTimestamp).format('DD.MM.YYYY HH:mm'),
  sourceDocDate: data.json.sourceDocDate
    ? moment(data.json.sourceDocDate).format('DD.MM.YYYY')
    : '—',
  original: {
    pg: data.pg,
    dropoutReason: data.dropoutReason,
    productionLineId: data.json.productLineId,
    productionOrderId: data.json.productOrderId,
    ...data.json,
    sourceDocDate: data.json.sourceDocDate ? new Date(data.json.sourceDocDate) : '',
    count: data.json.sntins.length,
  },
});

export default reducer;
