/* eslint-disable */
import fp from 'lodash/fp';
import types from '../types';
import { generateGuid } from '../../helpers';
import { get } from 'lodash';

const initialState = {
  errors: [],
  messages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ERROR:
      const { payload } = action;
      const errorList = parseErrors(payload);
      const errors = [ ...state.errors, ...errorList ];
      return {
        ...state,
        errors: [ ...errors ],
      };
    case types.ADD_MESSAGE:
      const list = parseMessages(action.payload);
      return {
        ...state,
        messages: [ ...state.messages, ...list ],
      };
    case types.CLEAR_ERRORS:
      return {
        ...state,
        // eslint-disable-next-line max-len
        errors: action?.payload ? [...state.errors].filter((item) => item.id !== action.payload) : [],
      };
    case types.CLEAR_MESSAGES:
      return {
        ...state,
        // eslint-disable-next-line max-len
        messages: action?.payload ? [...state.messages].filter((item) => item.id !== action.payload) : [],
      };
    default:
      return state;
  }
};

export const parseErrors = (payload) => {
  const globalErrors = fp.pipe(
    fp.get('response.data.globalErrors'),
    fp.map((item) => ({ id: generateGuid(), name: item.error }))
  )(payload);
  const errDesc = get(payload, 'response.data.description');
  const err = get(payload, 'response.data.error');
  const commonErrors = [];
  if (err) {
    commonErrors.push({ id: generateGuid(), name: `${err}`})
  }
  if (errDesc) {
    commonErrors.push({ id: generateGuid(), name: `${errDesc}`})
  }
  return [ ...globalErrors, ...commonErrors ]
};


const parseMessages = (message) => [
  { id: generateGuid(), name: message }
]

export default reducer;

