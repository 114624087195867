import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@crpt-ui/core';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStyles } from './auth.styled';
import { Logo } from '../../components/logo';
import Button from '../../components/crpt-ui-wrappers/button';
import TextField from '../../components/final-form/text-field';
import { login } from '../../store/actions/auth';
import { LanguageSwitcher } from '../../components/language-switcher/languageSwitcher';
import { Translate } from '../../components/translate';
import { ErrorNotification } from '../../components/notification';

const LoginPage = () => {
  const classes = useStyles();
  const dsp = useDispatch();
  const { countryCode } = useSelector((state) => state.settings.common);
  const { t } = useTranslation();

  const onSubmit = (values) => {
    dsp(login({ ...values }));
  };

  return (
    <div className={classes.authPage}>
      <ErrorNotification />
      <div className={classes.bg}>
        <div className={classes.logo}>
          <Logo country={countryCode} />
        </div>
        <Typography variant="h1" className={classes.header}>
          {
            Translate('ВХОД В ЕДИНЫЙ ЛИЧНЫЙ КАБИНЕТ')
          }
        </Typography>
      </div>
      <div className={classes.form}>
        <div className={classes.lang}>
          <LanguageSwitcher />
        </div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Field
                fullWidth
                component={TextField}
                name="username"
                type="text"
                label={t('Введите ваш ID')}
                autoFocus
                inputProps={{
                  maxLength: 64,
                }}
              />
              <Field
                fullWidth
                component={TextField}
                name="password"
                type="password"
                label={t('Пароль')}
              />
              <div className={classes.actions}>
                <Button
                  size="md"
                  variant="contained"
                  fullWidth
                  type="submit"
                  name="contained"
                  disabled={invalid}
                >
                  {t('Войти в личный кабинет')}
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export default LoginPage;
