/* eslint-disable no-param-reassign */
import { put } from 'redux-saga/effects';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { setList as setReportList } from '../actions/documents/drafts';
import { addError } from '../actions/errors';

export function* loadReportListSaga(action) {
  try {
    yield put(setIsLoading(true));

    const json = yield API.rest.reports.list(action.payload);

    yield put(setReportList({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}
