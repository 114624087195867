import qs from 'qs';

function request(url) {
  return (path = '', query = {}) => {
    const queryString = qs.stringify(query);
    return encodeURI(`${url}${path ? `/${path}` : ''}${queryString ? `?${queryString}` : ''}`);
  };
}

export const routes = {
  home: request('/'),
  auth: request('/auth'),
  orders: request('/orders'),
  rejections: request('/dropout'),
  aggregations: request('/aggregation'),
  marking: request('/utilisation'),
  documents: request('/documents'),
  devices: request('/devices'),
  stickers: request('/stickers'),
  guides: request('/guides'),
  products: request('/products'),
  profile: request('/profile'),
  about: request('/about'),
  login: request('/login'),
  serviceProviders: request('/service-providers'),
  serviceDistributions: request('/service-distributions'),
};

export default routes;
