import types from '../types';

const initialState = {
  list: [],
  item: {},
  totalElements: 0,
  filter: {},
  pagination: { page: 1, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STICKERS_SET_LIST:
      return {
        ...state,
        totalElements: action.payload.totalElements,
        list: action.payload.content,
      };
    case types.STICKERS_SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case types.SET_STICKERS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
