import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { clearErrors, clearMessages } from '../../store/actions/errors';
import './index.scss';
import { Translate } from '../translate';

export const ErrorNotification = () => {
  const dsp = useDispatch();
  const errors = useSelector((state) => state.errors.errors);
  const messages = useSelector((state) => state.errors.messages);

  const onClose = (item) => {
    if (item.type === 'error') {
      dsp(clearErrors(item.id));
    } else {
      dsp(clearMessages(item.id));
    }
  };
  const list = useMemo(() => {
    const filtered = errors.filter((item) => !!item.name).map((item) => ({ ...item, type: 'error' }));
    const success = messages.map((item) => ({ ...item, name: Translate(item.name || ''), type: 'success' }));
    return [...filtered, ...success];
  }, [errors, messages]);

  return (list.length > 0
    ? (
      <div className="alertContainer">
        {
          list.map(
            (item) => (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open
              >
                <Alert variant="filled" onClose={() => onClose(item)} severity={item.type} color={item.type}>
                  {item.name}
                </Alert>
              </Snackbar>
            ),
          )
        }
      </div>
    ) : null);
};
