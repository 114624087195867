export const palette = {
  primary: {
    main: '#f6f42e',
    iconColor: '#f6f42e',
    iconColorHover: '#888888',
  },
  bgColor: '#F5F5F5',
  secondary: {
    main: '#4aa5e8',
  },
  text: {
    primary: '#888888',
    secondary: '#868686',
    light: '#b8b8b8',
    white: '#888888',
  },
  error: {
    main: '#ff4b4b',
  },
  success: {
    main: '#60e84a',
  },
};
