import React from 'react';
import { Button } from '@crpt-ui/core';
import cn from 'classnames';
import { useStyles } from './button.styled';

const ButtonWrapper = ({ children, variant, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      className={cn(
        classes.button, {
          [classes.buttonContained]: variant === 'contained',
          [classes.buttonOutlined]: variant === 'outlined',
        },
      )}
      variant={variant}
      {...rest}
    >
      {children}
    </Button>
  );
};

ButtonWrapper.defaultProps = {
  variant: 'contained',
};

export default ButtonWrapper;
