import { createAction } from '../../helpers/redux';
import types from '../types';

export const set = createAction(types.SET_DEVICES);
export const getList = createAction(types.DEVICES_GET_LIST);
export const setList = createAction(types.DEVICES_SET_LIST);
export const getItem = createAction(types.DEVICES_GET_ITEM);
export const setItem = createAction(types.DEVICES_SET_ITEM);
export const deleteItem = createAction(types.DELETE_DEVICE);
export const removeDeviceFormStore = createAction(types.REMOVE_DEVICE_FROM_STORE);
export const create = createAction(types.CREATE_DEVICES);
