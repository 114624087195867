/* eslint-disable */
import { Input } from "@crpt-ui/core";
import React, { useEffect, useMemo, useRef } from "react";
import { Translate } from "../translate";
import { useTranslation } from "react-i18next";

const TextField = (props) => {
  const { t } = useTranslation();
  const {
    input: { name, onChange, value, ...restInput },
    label,
    meta,
    noTranslateValue = false,
    onCustomChange,
    isDate,
    ...rest
  } = props;

  const textFieldRef = useRef(null);

  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === "number") {
        e.target.blur();
      }
    };
    textFieldRef.current.addEventListener("wheel", handleWheel);

    return () => {
      textFieldRef.current &&
        textFieldRef.current.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const viewHelperText = useMemo(
    () => meta.error && (meta.dirty || meta.submitFailed) && meta.touched,
    [meta.error, meta.submitFailed, meta.touched, meta.dirty]
  );

  const error = useMemo(
    () =>
      meta.error
        ? typeof meta.error === "string"
          ? t(meta.error)
          : meta.error
        : undefined,
    [meta.error, meta.submitFailed, meta.touched, meta.dirty, t]
  );

  return (
    <Input
      {...rest}
      ref={textFieldRef}
      label={Translate(label)}
      name={name}
      helperText={viewHelperText ? error : undefined}
      error={error}
      // error={meta.error && (meta.dirty || meta.submitFailed) && meta.touched}
      InputProps={restInput}
      // onChange={onChange}
      onChange={(e) => {
        onChange(e);
        onCustomChange && onCustomChange(e.target.value);
      }}
      value={
        typeof value === "string" && !isDate && !noTranslateValue
          ? t(value)
          : value
      }
      margin="normal"
      required={rest.required || (!!value && !meta.error)}
      // required={!meta.error && (!meta.dirty || !meta.submitFailed) && meta.touched}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default TextField;
