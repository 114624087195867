/* eslint-disable */
import moment from 'moment';
import fp from 'lodash/fp';
import types from '../types';
import { CREATE_METHOD_TYPES_NAMES, ORDER_STATUSES, RELEASE_METHOD_TYPES_NAMES } from '../../pages/orders/constants';
import { MARK_CODE_TYPES_NAMES, PRODUCT_GROUPS_NAMES } from '../../constants';
import { getCountryName } from '../../utils/utils';

const initialState = {
  list: [],
  codesRetryList: [],
  item: {},
  isError: false,
  totalElements: 0,
  filter: {},
  pagination: { page: 1, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ORDERS_SET_LIST:
      return {
        ...state,
        totalElements: action.payload?.totalElements,
        list: parseOrdersList(action.payload),
      };
    case types.ORDERS_SET_RETRY_LIST:
      return {
        ...state,
        codesRetryList: parseRetryList(action.payload),
      };
    case types.ORDERS_SET_ITEM:
      return {
        ...state,
        item: parseOrderItem(action.payload),
      };
    case types.SET_ORDER:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_ORDER:
      return {
        ...state,
        item: {},
        codesRetryList: [],
      };
    case types.ORDER_SET_ITEM_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

const parseOrdersList = (data) => {
  return fp.pipe(
    fp.get('orderInfos'),
    fp.orderBy(['createDate'], ['desc']),
    fp.map(
      (item) => ({
        ...item,
        createDate: moment(item.createDate).format('DD.MM.YYYY HH:mm'),
        productCount: item.buffers?.length || 0,
        codesCount: item.buffers
          ? item.buffers.reduce((acc, product) => acc + parseInt(product.quantity, 10), 0)
          : 0,
      }),
    ),
  )(data);
};

const parseOrderItem = (order) => ({
  ...order,
  pg: PRODUCT_GROUPS_NAMES[order.pg],
  pgValue: order.pg,
  factoryCountry: order?.factory?.country ? getCountryName(order.factory.country) : '—',
  orderStatus: ORDER_STATUSES[order.status],
  releaseMethodType: RELEASE_METHOD_TYPES_NAMES[order.releaseMethodType],
  createMethodType: CREATE_METHOD_TYPES_NAMES[order.createMethodType],
  createDate: moment(order.createDate).format('DD.MM.YYYY HH:mm'),
  products: fp.pipe(
    fp.get('buffers'),
    fp.map(
      (v) => ({
        ...v,
        cisType: MARK_CODE_TYPES_NAMES[v.cisType],
      }),
    ),
    fp.orderBy(['gtin'], ['asc']),
  )(order),
});

const parseRetryList = (data) => {
  return fp.pipe(
    fp.get('list'),
    fp.map(
      (item) => ({
        ...item,
        count: item.cisCount,
        lastPackId: item.packId,
        packDateTime: moment(item.packDateTime).format('DD.MM.YYYY HH:mm'),
      }),
    ),
  )(data);
};
