/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationKZ from './locales/kz/translations.json';
import translationKG from './locales/kg/translations.json';
import translationRU from './locales/ru/translations.json';
import translationUZ from './locales/uz/translations.json';
import translationEN from './locales/en/translations.json';

const resources = {
  KZ: {
    translation: translationKZ,
  },
  KG: {
    translation: translationKG,
  },
  AM: {
    translation: translationKZ,
  },
  UZ: {
    translation: translationUZ,
  },
  EN: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ru',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
