import moment from 'moment';
import { isNil } from 'lodash';
import types from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import { MARKING_OPTIONS_NAMES, MARKING_STATUSES } from '../../pages/marking/constants';

const initialState = {
  list: [],
  item: {},
  isError: false,
  totalElements: 0,
  filter: {},
  pagination: { page: 0, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MARKING_SET_LIST:
      return {
        ...state,
        totalElements: action.payload.totalElements,
        list: action.payload.content,
      };
    case types.MARKING_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.CLEAR_MARKING:
      return {
        ...state,
        item: {},
      };
    case types.MARKING_SET_ITEM_ERROR:
      return {
        ...state,
        isError: action.payload,
      };
    case types.SET_MARKING:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

const parseItem = (data) => ({
  ...data,
  pgName: PRODUCT_GROUPS_NAMES[data.pg],
  statusName: MARKING_STATUSES[data.status],
  products: data.json?.utilisationReport?.sntins
    ? data.json.utilisationReport.sntins.map((item) => ({ code: item }))
    : data.json?.sntins?.map((item) => ({ code: item })),
  // createdTimestamp: moment(parseInt(data.createdTimestamp, 10)).format('DD.MM.YYYY HH:mm'),
  createdTimestamp: moment(data.createdTimestamp).format('DD.MM.YYYY HH:mm'),
  json: {
    productionLineId: data.json.productionLineId,
    sntins: data.json.sntins,
    // eslint-disable-next-line no-nested-ternary
    usedInProduction: data.json.usedInProduction ? 'Да' : isNil(data.json.usedInProduction) ? '—' : 'Нет',
    usageType: MARKING_OPTIONS_NAMES[data?.json?.usageType],
    productionDate: data.json.productionDate ? moment(data.json.productionDate).format('DD.MM.YYYY') : '—',
    expirationDate: data.json.expirationDate ? moment(data.json.expirationDate).format('DD.MM.YYYY') : '—',
    productionOrderId: data.json.productionOrderId,
    brandcode: data.json.brandcode,
    seriesNumber: data.json.seriesNumber ? data.json.seriesNumber : '—',
    sourceReportId: data.json.sourceReportId,
    isAutoReport: data.isAutoReport ? 'Автоматически' : 'Пользователем',
  },
  original: {
    pg: data.pg,
    sntins: data.json.sntins,
    productionLineId: data.json.productionLineId,
    productionOrderId: data.json.productionOrderId,
    brandcode: data.json.brandcode,
    seriesNumber: data.json.seriesNumber,
    sourceReportId: data.json.sourceReportId,
    usageType: data?.json?.usageType,
    productionDate: data.json.productionDate ? new Date(data.json.productionDate) : '',
    expirationDate: data.json.expirationDate ? new Date(data.json.expirationDate) : '',
    count: data.json?.sntins?.length || data.json?.utilisationReport?.sntins?.length,
  },
});
