import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import PageMenu from './menu';
import './index.scss';
import { Spinner } from '../spinner';
import { routes } from '../../routes';
import { ErrorNotification } from '../notification';
import { HeaderView } from './header';

const OrdersContainer = lazy(() => import('../../pages/orders/ordersContainer'));
const ReportsContainer = lazy(() => import('../../pages/reports/reportsContainer'));
const DeviceContainer = lazy(() => import('../../pages/devices/deviceContainer'));
const SPContainer = lazy(() => import('../../pages/serviceProviders/serviceProviderContainer'));
const SDContainer = lazy(() => import('../../pages/serviceDistributions/serviceDistributionsContainer'));
const AuthPage = lazy(() => import('../../pages/auth/LoginPage'));
// const StickersPage = lazy(() => import('../../pages/stickers'));
// const StickerForm = lazy(() => import('../../pages/stickers/stickerForm'));
// const ProductsPage = lazy(() => import('../../pages/products'));
const ProfilePage = lazy(() => import('../../pages/profile'));
const AboutPage = lazy(() => import('../../pages/profile/about'));

const Layout = () => (
  <div className="layout">
    <div className="header">
      <HeaderView />
    </div>
    <div className="nav">
      <PageMenu />
    </div>
    <ErrorNotification />
    <div className="main">
      <div className="page">
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path={routes.orders()} component={OrdersContainer} />
            <Route path={routes.serviceProviders()} component={SPContainer} />
            <Route path={routes.serviceDistributions()} component={SDContainer} />
            <Route exact path={routes.login()} component={AuthPage} />
            <Route exact path="/">
              <Redirect to={routes.orders()} />
            </Route>
            <Route path={routes.aggregations()} component={ReportsContainer} />
            <Route path={routes.marking()} component={ReportsContainer} />
            <Route path={routes.rejections()} component={ReportsContainer} />
            <Route path={routes.documents()} component={ReportsContainer} />
            <Route path={routes.devices()} component={DeviceContainer} />
            {/* <Route exact path={routes.stickers()} component={StickersPage} /> */}
            {/* <Route exact path={routes.stickers('create')} component={StickerForm} /> */}
            {/* <Route exact path={routes.products()} component={ProductsPage} /> */}
            <Route exact path={routes.profile()} component={ProfilePage} />
            <Route exact path={routes.about()} component={AboutPage} />
            <Route
              path="*"
              render={
                ({ location }) => (
                  <h3>
                    404:
                    {location.pathname}
                  </h3>
                )
              }
            />
          </Switch>
        </Suspense>
      </div>
    </div>
  </div>
);

export const mapStateToProps = () => ({});

const ConnectedComponent = connect(mapStateToProps, {})(Layout);

export default ConnectedComponent;
