import types from '../types';
import { getCookie } from '../../utils/utils';

const initialState = {
  common: {
    countryCode: window?.env?.COUNTRY_CODE || 'KZ',
  },
  isLoading: false,
  language: getCookie('language') || 'RU',
  settings: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_COMMON_SETTINGS:
      return {
        ...state,
        common: action.payload,
      };
    case types.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case types.SET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case types.CLEAR_SETTINGS:
      return {
        ...state,
        settings: null,
      };
    default:
      return state;
  }
};

export default reducer;
