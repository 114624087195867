import types from '../types';

const initialState = {
  list: [],
  item: {},
  totalElements: 0,
  filter: {},
  pagination: { page: 1, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DEVICES_SET_LIST:
      return {
        ...state,
        totalElements: action.payload?.totalElements || 0,
        list: action.payload.data,
      };
    case types.REMOVE_DEVICE_FROM_STORE:
      return {
        ...state,
        list: [...state.list].filter((item) => item.token !== action.payload),
      };
    case types.DEVICES_SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case types.SET_DEVICES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
