import { createAction } from '../../helpers/redux';
import types from '../types';

export const set = createAction(types.SET_ORDER);
export const getList = createAction(types.ORDERS_GET_LIST);
export const setList = createAction(types.ORDERS_SET_LIST);
export const getItem = createAction(types.ORDERS_GET_ITEM);
export const setItem = createAction(types.ORDERS_SET_ITEM);
export const createOrder = createAction(types.CREATE_ORDER);
export const closeOrder = createAction(types.CLOSE_ORDER);
export const sendOrder = createAction(types.SEND_ORDER);
export const printOrderCodes = createAction(types.PRINT_ORDER_CODES);
export const clear = createAction(types.CLEAR_ORDER);
export const setError = createAction(types.ORDER_SET_ITEM_ERROR);
export const getRetryCodeList = createAction(types.ORDERS_GET_RETRY_LIST);
export const setRetryCodeList = createAction(types.ORDERS_SET_RETRY_LIST);
