import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandMore } from '@material-ui/icons';
import { saveLanguage } from '../../store/actions/common';
import DropDown from '../DropDown/DropDown';
import { getCookie, setCookie } from '../../utils/utils';

export const LanguageSwitcher = () => {
  const [language, setLanguage] = useState('RU');
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const country = useSelector((state) => state?.settings?.common?.countryCode);

  const changeLanguage = useCallback((lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    dispatch(saveLanguage(lang));
    setCookie('language', lang);
    document.title = t('Asl Belgisi');
  }, [dispatch, i18n, t]);

  useEffect(() => {
    const currentLanguage = getCookie('language') || 'RU';
    changeLanguage(currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const btn = (props) => (
    <button type="button" className="language" {...props}>
      {language}
      <ExpandMore />
    </button>
  );

  const items = useMemo(() => [
    {
      title: 'RU',
      onClick: (close) => {
        changeLanguage('RU');
        close();
      },
    },
    {
      title: 'EN',
      onClick: (close) => {
        changeLanguage('EN');
        close();
      },
    },
    {
      title: country,
      onClick: (close) => {
        changeLanguage(country.toUpperCase());
        close();
      },
    }], [changeLanguage, country]);

  return (
    <div className="languageSwitcher">
      <DropDown active={language} renderButton={btn} items={items} />
    </div>
  );
};
