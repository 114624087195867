export const palette = {
  primary: {
    main: '#707070',
    iconColor: '#cd1e24',
    iconColorHover: '#fff',
  },
  bgColor: '#F5F5F5',
  secondary: {
    main: '#707070',
  },
  text: {
    primary: '#888888',
    secondary: '#868686',
    light: '#b8b8b8',
    disabled: '#d0d0d0',
    white: '#ffffff',
  },
  error: {
    main: '#ff4b4b',
  },
  success: {
    main: '#60e84a',
  },
};
