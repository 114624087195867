import types from '../types';

const initialState = {
  list: [],
  item: {},
  totalElements: 0,
  filter: {},
  pagination: { page: 1, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SD_SET_LIST:
      return {
        ...state,
        totalElements: action.payload?.totalElements || 0,
        list: parseList(action.payload.clients),
      };
    case types.SD_SET_ITEM:
      return {
        ...state,
        item: action.payload,
      };
    case types.SET_SD:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_SD:
      return {
        ...state,
        item: {},
      };
    default:
      return state;
  }
};

export default reducer;

const parseList = (data) => data.map(
  (item) => ({
    ...item,
    active: item.active ? 'Активный' : 'Заблокированный',
  }),
);
