import { put, call } from 'redux-saga/effects';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { setItem } from '../actions/validation';
import { loadServiceProvidersSaga } from './serviceProviders';

export function* loadValidationItemSaga(action) {
  const { id } = action.payload;
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(id);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
    yield call(loadServiceProvidersSaga);
  } catch (err) {
    yield put(setIsLoading(false));
  }
}
