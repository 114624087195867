import { put } from 'redux-saga/effects';
import moment from 'moment';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { addError } from '../actions/errors';
import {
  setItem, setList, setError,
} from '../actions/marking';
import { history } from '../../store';
import { routes } from '../../routes';

export function* loadMarkingListSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.marking.list(action.payload);
    yield put(setList({ ...mockListData(json) }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadMarkingItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(action.payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(setError(true));
    yield put(addError(err));
  }
}

export function* createMarkingSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.utilisation(payload.data, payload.query);
    yield put(setIsLoading(false));
    yield history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* updateMarkingSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.utilisationUpdate(payload.data, payload.query, payload.reportId);
    yield put(setIsLoading(false));
    yield history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export const mockListData = (list) => ({
  content: list.map(
    (item, idx) => ({
      id: item.id,
      productGroupType: idx % 2 === 0 ? 'medicine' : 'tobacco',
      creationDate: moment().format('DD.MM.YYYY'),
      codesCount: idx,
      status: item.title,
      signed: idx % 2 === 0,
    }),
  ),
  totalElements: list.length,
});
