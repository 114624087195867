import React, { useMemo } from 'react';
import Header, { Button, MenuButton } from '@crpt-ui/header';
import Grid from '@material-ui/core/Grid';
import Icon from '@crpt-ui/core/lib/Icon/Icon';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { HELPERS } from '../../../helpers';
import { Translate } from '../../translate';
import { LanguageSwitcher } from '../../language-switcher/languageSwitcher';
import { logout } from '../../../store/actions/auth';
import { getShortName } from '../../../utils/utils';
import { history } from '../../../store';
import { routes } from '../../../routes';

export const HeaderView = () => {
  const location = useLocation();
  const user = useSelector((state) => state?.currentUser);

  const userName = useMemo(() => (!isEmpty(user) ? getShortName(user.full_name) : ''), [user]);

  const title = HELPERS.getPageTitle(location.pathname);
  const dsp = useDispatch();

  const onLogout = () => {
    dsp(logout());
  };

  const onProfileClick = () => {
    history.push(routes.profile());
  };

  const onAboutClick = () => {
    history.push(routes.about());
  };

  return (
    <Header>
      <Grid container>
        <Grid item>
          <Button>
            {Translate(title)}
          </Button>
        </Grid>
        <Grid item style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
          <LanguageSwitcher />
        </Grid>
        <Grid item>
          <MenuButton
            icon={(
              <div>
                <Icon name="User" />
              </div>
            )}
            items={[
              {
                label: Translate('Обо мне'),
                icon: <Icon name="Information" />,
                onClick: onAboutClick,
              },
              {
                label: Translate('Профиль'),
                icon: <Icon name="User" />,
                onClick: onProfileClick,
              },
              {
                label: Translate('Выйти'),
                icon: <Icon name="signout" />,
                onClick: onLogout,
              },
            ]}
          >
            {userName}
          </MenuButton>
        </Grid>
      </Grid>
    </Header>
  );
};
