import React from 'react';
import * as Icons from '@crpt-ui/icons';
import { PRODUCT_GROUPS_NAMES } from '../../../constants';
import { Translate } from '../../translate';
import { ReactComponent as Antiseptic } from '../../../assets/logos/antiseptic.svg';
import { ReactComponent as Appliances } from '../../../assets/logos/appliances.svg';
import { ReactComponent as Medicals } from '../../../assets/logos/medicals.svg';
import './cells.scss';

export const PRODUCT_GROUPS_ICONS = {
  milk: <Icons.Milk />,
  pharma: <Icons.Medicines />,
  alcohol: <Icons.Alcohol />,
  shoes: <Icons.Shoes />,
  tobacco: <Icons.Tobacco />,
  lp: <Icons.Clothes />,
  appliances: <Appliances />,
  beer: <Icons.Beer />,
  water: <Icons.Water />,
  antiseptic: <Antiseptic />,
  medicals: <Medicals />,
};

export const ProductGroupCell = ({ pg }) => (
  <div className="pgCell">
    <div>{PRODUCT_GROUPS_ICONS[pg]}</div>
    <div>{Translate(PRODUCT_GROUPS_NAMES[pg])}</div>
  </div>
);
