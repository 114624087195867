import moment from 'moment';
import types from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import i18n from '../../i18n';
import { STATUSES } from '../../pages/validation/contants';

const initialState = {
  list: [],
  item: {},
  id: null,
  totalElements: 0,
  filter: {},
  pagination: { page: 0, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VALIDATION_SET_LIST:
      return {
        ...state,
        totalElements: action.payload.totalElements,
        list: action.payload.content,
      };
    case types.VALIDATION_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.SET_VALIDATION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const parseItem = (data) => (
  {
    ...data,
    auto_report: data?.isAutoReport,
    pgName: i18n.t(PRODUCT_GROUPS_NAMES[data.pg]),
    createdTimestamp: moment(data.createdTimestamp).format('DD.MM.YYYY HH:mm'),
    statusName: STATUSES?.[data.status] ?? '',
    rejectedReason: data?.rejectedReason,
  }
);

export default reducer;
