import React from 'react';
import './cells.scss';
import { Link } from 'react-router-dom';

export const LinkCell = ({ link, text, ...props }) => (
  <Link
    {...props}
    className="linkCell"
    to={link}
  >
    {text}
  </Link>

);
