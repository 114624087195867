import fp from 'lodash/fp';
import moment from 'moment';
import types from '../types';

const initialState = {
  list: [],
  totalElements: 0,
  filter: {},
  pagination: { page: 1, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REPORT_SET_LIST:
      return {
        ...state,
        list: parseReportList(action.payload),
        totalElements: action.payload.totalElements,
      };
    case types.SET_REPORTS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

const parseReportList = (data) => fp.pipe(
  fp.get('reportInfos'),
  fp.map(
    (item) => ({
      ...item,
      creationDate: moment(item.createdTimestamp).format('DD.MM.YYYY HH:mm'),
      // creationDate: moment(parseInt(item.createdTimestamp, 10)).format('DD.MM.YYYY HH:mm'),
    }),
  ),
)(data);
