import { put } from 'redux-saga/effects';
import moment from 'moment';
import { addError } from '../actions/errors';
import API from '../../api';
import { setIsLoading, setSettings } from '../actions/common';

export function* downloadFileSaga(action) {
  try {
    const date = moment().format('YYYYMMDDHHmmss');
    const fileName = `_download_${date}.csv_`;
    yield API.rest.files.download(action.payload, fileName);
  } catch (err) {
    yield put(addError(err));
  }
}

export function* uploadFileSaga(action) {
  try {
    const json = yield API.rest.codes.validate([...action.payload.files], '', action.payload.query);
    action.payload.onFinish(json);
  } catch (err) {
    action.payload.onFinish(false);
    yield put(addError(err));
  }
}

export function* loadSettingsSaga() {
  try {
    yield put(setIsLoading(true));
    yield put(setIsLoading(false));
    const json = yield API.rest.settings.item();
    yield put(setSettings(json));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}
