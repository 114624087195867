import axios from 'axios';
import { setAuthData } from '../store/actions/auth';
import { storeTokens } from '../store/sagas/auth';
import API from './index';
import { getParamFromToken } from '../utils/utils';

let isRefreshing = false;
let subscribers = [];

function onRefreshed({ access_token }) {
  subscribers.map((cb) => cb(access_token));
}

function subscribeTokenRefresh(cb) {
  subscribers.push(cb);
}

const incerceptor = (store) => {
  axios.interceptors.response.use(null, async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401) {
      if (originalRequest.url === '/oauth/oauth2' && originalRequest.method !== 'delete') {
        return Promise.reject(error.response);
      }
      if (!isRefreshing) {
        isRefreshing = true;
        API.rest.auth.reconnect().then(
          (token) => {
            const exp_date = getParamFromToken('exp', token, 'access_token');
            const exp_date_refresh = getParamFromToken('exp', token, 'refresh_token');
            storeTokens({ ...token, exp_date, exp_date_refresh });
            // eslint-disable-next-line max-len
            store.dispatch(setAuthData(token ? { ...token, exp_date, exp_date_refresh } : { isNotAuth: true }));
            isRefreshing = false;
            if (token) {
              onRefreshed(token);
            }
            subscribers = [];
          },
        );
      }
      return new Promise((resolve) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  });
};
export default incerceptor;
