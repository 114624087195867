import { put } from 'redux-saga/effects';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { addError } from '../actions/errors';
import { setParticipantDistribution, setProfileData } from '../actions/participants';
import { getCookie, getParamFromToken } from '../../utils/utils';

export function getPID() {
  const access_token = localStorage.getItem('access_token') || '';
  return access_token
    ? getParamFromToken('pid', { access_token }, 'access_token')
    : '';
}

export function* loadProfileSaga() {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.profile();
    yield put(setProfileData({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    if (
      err.response.data?.globalErrors
        && err.response.data?.globalErrors[0].errorCode === 101
        && err.response.data?.globalErrors[0].error === 'Маркер безопасности не действителен'
    ) {
      const pointOfEnter = getCookie('pointOfEnter') || '/login-kep';
      window.open(`${window.env.REACT_APP_ELK_URL}${pointOfEnter}`, '_self');
    }
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* updateDistributionSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.participants.distribution(payload);
    yield put(setParticipantDistribution({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}
