import React from 'react';
import cn from 'classnames';
import * as Icons from '@crpt-ui/icons';
import { PRODUCT_STATUSES } from '../constants';

const ViewProductStatus = ({ cell, row }) => (
  <div className="viewProductStatus">
    {' '}
    <div className={cn('statusName', { reject: cell.value === 'REJECTED' })}>
      {PRODUCT_STATUSES[cell.value]}
    </div>
    {
      cell.value === 'REJECTED' && (
        <>
          {' '}
          <div title={row.original.rejectionReason}>
            <Icons.Information />
          </div>
        </>
      )
    }
    {' '}
  </div>
);

export default ViewProductStatus;
