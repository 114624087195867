import { createAction } from '../../helpers/redux';
import types from '../types';

export const set = createAction(types.SET_SD);
export const getList = createAction(types.SD_GET_LIST);
export const setList = createAction(types.SD_SET_LIST);
export const getItem = createAction(types.SD_GET_ITEM);
export const setItem = createAction(types.SD_SET_ITEM);
export const clear = createAction(types.CLEAR_SD);
export const create = createAction(types.CREATE_SD);
export const update = createAction(types.UPDATE_SD);
