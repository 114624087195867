import { put } from 'redux-saga/effects';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { addError } from '../actions/errors';
import { setItem, setList } from '../actions/stickers';
import { history } from '../../store';
import { routes } from '../../routes';

export function* loadStickerListSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.stickers.list(action.payload);
    yield put(setList({ ...mockListData(json) }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadStickerItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.stickers.item(action.payload);
    yield put(setItem({ ...mockItemData(json) }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* createStickerSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.stickers.create(payload);
    yield put(setItem({ ...mockItemData(json) }));
    yield put(setIsLoading(false));
    yield history.push(routes.stickers());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export const mockListData = (list) => ({
  content: list.map(
    (item, idx) => ({
      id: item.id,
      productGroupType: idx % 2 === 0 ? 'medicine' : 'tobacco',
      name: item.title,
      type: item.title,
      isDefault: idx % 2 === 0 ? 'Да' : 'Нет',
      show: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/1200px-QR_code_for_mobile_English_Wikipedia.svg.png',
    }),
  ),
  totalElements: list.length,
});

const mockItemData = () => ({
  id: '391b8411-031a-4f80-8be7-fbb689523ccc',
});
