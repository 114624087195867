/* eslint-disable */
import axios from 'axios';
import qs from 'qs';

import {
  checkContentType,
  CONTENT_TYPES, getAcceptLanguage,
  getContentDispositionFilename,
  isHasBody,
  isNeedVersionApi,
  saveAsFile,
} from '../helpers/http';
import Errors from './errors';
import { URL_BASE } from './constants';

const VERSION_API = '';
const BASIC = {
  username: 'crpt-service', password: 'secret',
};

const resolve = ({ response, saveAs, fileName }) => {
  const isContentType = checkContentType(response);
  const response_json = isContentType(CONTENT_TYPES.json) && response.data;
  switch (response.status) {
    case 200:
    case 201:
    case 202:
      return (
        saveAs
          ? saveAsFile(
          response, fileName || getContentDispositionFilename(response, saveAs),
          )
          : (response_json || response)
      );
    case 204:
      return null;
    default:
      console.warn(`Unknown response status: ${response.status}\n`, response);
      return null;
  }
};

const reject = (response) => {
  if (response.request.responseType === 'blob' && response.data instanceof Blob) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        response.data = JSON.parse(reader.result);
        resolve(Promise.reject(response));
      };
      reader.onerror = () => {
        reject(response);
      };
      reader.readAsText(response.data);
    });
  }
  switch (response.status) {
    case 401: { return; }
    case 403:
      throw new Errors.Forbidden(response);
    case 400:
      throw new Errors.BadRequest(response);
    case 500:
      throw new Errors.Backend(response);
    default:
      throw new Errors.Unknown(response, response.data);
  }
};

const options = ({
                   access_token,
                   basic,
                   body,
                   content_type = CONTENT_TYPES.json,
                   headers = {},
                   method = 'POST',
                   onDownloadProgress,
                   onUploadProgress,
                   path = '',
                   query = {},
                   responseType,
                   token_type,
                   url = (() => { throw new Error('Url is not specified'); })(),
                   hideError,
                   withBody,
                 }) => {
  const hasBody = isHasBody(method) || withBody;
  const data = body && hasBody && (
    content_type === CONTENT_TYPES.json
      ? JSON.stringify(body)
      : content_type === CONTENT_TYPES.form
      ? qs.stringify(body)
      : body
  );
  const url_path = path ? `/${path}` : '';
  const url_query = qs.stringify({ ...query, ...!hasBody && body }, { arrayFormat: 'repeat' });
  const url_version_api = isNeedVersionApi(url) ? '' : VERSION_API;
  const url_base = url === '/oauth' ? '' : URL_BASE;
  const url_request = [url_base, url_version_api, url, url_path, url_query ? '?' : '', url_query].join('');

  return {
    method,
    ...data && { data },
    ...basic && { auth: basic },
    headers: {
      ...hasBody && { 'Content-Type': content_type },
      ...access_token && { Authorization: `Bearer ${access_token}` },
      ...headers,
    },
    responseType,
    onUploadProgress,
    onDownloadProgress,
    url: url_request,
    hideError,
    timeout: 900000,
  };
};

const request = ({
                   parent,
                   reconnect = reject,
                   saveAs,
                   fileName,
                   ...props
                 }) => (
  axios(options(props))
    .then(
      (response) => resolve({ response, saveAs, fileName }),
      parent
        ? reject
        : ({ response }) => reject(response),
    )
);

const connect = (props) => {
  const { reconnect } = props;
  const { access_token, refresh_token, token_type } = props.auth || {};
  const method = ({ name, ...opts }) => (args) => request({
    access_token, token_type, ...opts, ...args, reconnect, method: name,
  });

  const get = method({ name: 'GET' });
  const post = method({ name: 'POST' });
  const put = method({ name: 'PUT' });
  const patch = method({ name: 'PATCH', content_type: CONTENT_TYPES.patch });
  const remove = method({ name: 'DELETE' });

  const crud = (url) => ({
    create: (body, query) => post({ url, body, query }),
    item: (id) => get({ url, path: id }),
    list: (query = {}) => get({ url, query }),
    patch: ({ id, body }) => patch({ url, path: id, body }),
    remove: (id) => remove({ url, path: id }),
    update: ({ id, body }) => put({ url, path: id, body }),
    download: (id, onDownloadProgress) => get({
      url,
      path: id,
      responseType: 'blob',
      onDownloadProgress,
      saveAs: `download-${id}.file`,
    }),
    upload: (files, onUploadProgress) => window.Promise.all(
      files.map(
        (file) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            content_type: CONTENT_TYPES.formdata,
            onUploadProgress,
            body,
          });
        },
      ),
    ),
  });

  const auth = (url) => ({
    check: (token) => (
      post({
        url,
        basic: BASIC,
        path: 'check_token',
        query: { token },
      })
    ),
    reconnect: (token = null) => (
      post({
        url,
        parent: true,
        path: 'oauth2',
        basic: BASIC,
        content_type: CONTENT_TYPES.form,
        body: { grant_type: 'refresh_token', refresh_token: token ? token : refresh_token },
      })
    ),
    signin: ({ username, password }) => (
      post({
        url,
        basic: BASIC,
        path: 'oauth2',
        content_type: CONTENT_TYPES.form,
        body: { grant_type: 'password', username, password }
      })
    ),
    signout: () => remove({ url, path: 'token' }),
  });

  const file = (url) => ({
    uploadFile: (files, onUploadProgress) => window.Promise.all(
      files.map(
        (file) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            content_type: CONTENT_TYPES.default,
            onUploadProgress,
            body,
          });
        },
      ),
    ),
    download: (query, fileName) => (
      get({
        url,
        path: 'csv',
        query,
        content_type: CONTENT_TYPES.csv,
        responseType: 'blob',
        saveAs: 'mark-codes.csv',
        fileName,
      })
    ),
  });

  const codes = (url) => ({
    ...crud(url),
    retryList: (query = {}) => get({ url, query, path: 'retry-list' }),
    print: (body, fileName, content_type) => (
      post({
        url,
        path: 'print',
        body,
        content_type,
        responseType: 'blob',
        saveAs: fileName,
        // fileName,
      })
    ),
    validate: (files, onUploadProgress, query) => window.Promise.all(
      files.map(
        (file) => {
          const body = new window.FormData();
          body.append('part', file);
          return post({
            url,
            path: 'validate',
            query,
            content_type: CONTENT_TYPES.default,
            onUploadProgress,
            body,
          });
        },
      ),
    ),
  });

  const reports = (url) => ({
    ...crud(url),
    utilisation: (body, query) => (
      post({ url, body, query, path: 'utilisation' })
    ),
    utilisationUpdate: (body, query, reportId) => (
      put({ url, body, query, path: `utilisation/${reportId}` })
    ),
    aggregation: (body, query) => (
      post({ url, body, query, path: 'aggregation' })
    ),
    aggregationUpdate: (body, query, reportId) => (
      put({ url, body, query, path: `aggregation/${reportId}` })
    ),
    dropout: (body, query) => (
      post({ url, body, query, path: 'dropout' })
    ),
    dropoutUpdate: (body, query, reportId) => (
      put({ url, body, query, path: `dropout/${reportId}` })
    ),
    validation: (body, query) => (
      post({ url, body, query, path: 'validation' })
    ),

  });

  const devices = (url) => ({
      ...crud(url),
      remove: (id) => remove({ url: `${url}/token`, path: id }),
  })

  const orders = (url) => ({
    ...crud(url),
    close: (query) => (
      post({ url, query, path: 'close' })
    ),
  });

  const participants = (url) => ({
    ...crud(url),
    profile: () => (
      get({ url, path: `profile` })
    ),
    sites: () => (
      get({ url, path: `sites` })
    ),
    deleteAllPGFromSite: (siteId, productGroups) => (
      remove({ url, path: `site/${siteId}`, body: { productGroups }, withBody: true })
    ),
    distribution: (decision) => (
      post({ url, path: `distribution/${decision}` })
    ),
    createConnection: (participantId, siteId, body) => (
      put({ url, path: `site/${siteId}/connected`, body })
    ),
  });

  const spdn = (url) => ({
    ...crud(url),
  });

  const order = (url) => ({
    close: (query) => (
      post({ url, query, path: 'close' })
    ),
  });

  return {
    get,
    remove,
    post,
    put,
    patch,
    auth: auth('/auth'),
    orders: orders('/orders'),
    order: order('/order'),
    codes: codes('/codes'),
    rejections: crud('/posts'),
    devices: devices('/devices'),
    stickers: crud('/posts'),
    aggregations: crud('/posts'),
    reports: reports('/reports'),
    progressDocs: crud('/posts'),
    finishedDocs: crud('/posts'),
    exportDocs: crud('/posts'),
    settings: crud('/settings'),
    files: file('/files'),
    spdn: spdn('/spdn/clients'),
    participants: participants('/participant'),
    serviceProviders: crud('/service-providers')
  };
};

export default connect;
