/* eslint-disable */
import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import * as Icons from '@crpt-ui/icons';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import routes from '../../../routes';
import { Translate } from '../../translate';
import Menu from '@crpt-ui/menu';
import MenuItem from './menuItem';
import HeaderLogo from './header-logo';
import { isMpOperator } from '../../../helpers';
import { isAdmin } from '../../../utils/utils';

const menuItems = [
  {
    label: 'Заказы',
    value: routes.orders(),
    icon: <Icons.Orders/>,
    role: 'ALL',
  },
  {
    label: 'Отчеты',
    value: routes.documents(),
    icon: <Icons.Marking/>,
    role: 'ALL',
  },
  {
    label: 'Сервис-провайдеры',
    value: routes.serviceProviders(),
    icon: <Icons.Aggregation/>,
    role: 'DISTRIBUTOR',
  },
  {
    label: 'Сети распределения',
    value: routes.serviceDistributions(),
    icon: <Icons.Applications/>,
    role: 'OPERATOR',
  },
  {
    label: 'Устройства',
    value: routes.devices(),
    icon: <Icons.Screen/>,
    role: 'ADMIN',
  },
];

const PageMenu = () => {
  const history = useHistory();
  const location = useLocation();
  const participant = useSelector((state) => state?.participants?.item);
  const pgList = useSelector((state) => state?.currentUser?.product_group_info);
  const userRoles = useMemo(() => {
    const roles = ['ALL'];
    if (participant.distribution) roles.push('DISTRIBUTOR');
    if (isMpOperator(pgList)) roles.push('OPERATOR');
    if (isAdmin()) roles.push('ADMIN');
    return roles;
  }, [pgList, participant]);

  const isActive = (path) => (path === '/'
    ? location.pathname === path
    : location.pathname.startsWith(path));

  const menuList = useMemo(() => {
    return menuItems.filter((item) => userRoles.includes(item.role))
  }, [userRoles]);

  return (
    <Menu expandable header={<HeaderLogo/>}>
      {menuList.map((item) => (
        <MenuItem
          icon={item.icon || <Icons.Menu/>}
          key={item.value}
          active={isActive(item.value)}
          onClick={() => history.push(item.value)}
        >
          {Translate(item.label)}
        </MenuItem>
      ))}
    </Menu>
  );
};

export const mapStateToProps = () => ({});

const ConnectedComponent = connect(mapStateToProps, {})(PageMenu);

export default ConnectedComponent;
