import types from '../types';

const initialState = {
  item: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PARTICIPANT_SET_PROFILE:
      return {
        ...state,
        item: {
          ...action.payload,
          tin: `${action.payload.tin}`,
          distributionValue: action.payload.distribution ? 'Да' : 'Нет',
        },
      };
    case types.PARTICIPANT_CLEAR_PROFILE:
      return {
        ...state,
        item: {},
      };
    case types.PARTICIPANT_SET_DISTRIBUTION:
      return {
        ...state,
        item: {
          ...state.item,
          distributionValue: action.payload.distribution ? 'Да' : 'Нет',
          distribution: action.payload.distribution,
        },
      };
    default:
      return state;
  }
};

export default reducer;
