const types = {
  SET_IS_LOADING: '[SAGS/SETTINGS]/SET_IS_LOADING',
  SET_COMMON_SETTINGS: '[SAGS/SETTINGS]/SET_COMMON_SETTINGS',
  SET_LANGUAGE: '[SAGS/SETTINGS]/SET_LANGUAGE',

  SET_ERRORS: '[SAGS/ERRORS]/SET_ERRORS',

  ADD_ERROR: '[ERROR]/ADD_ERROR',
  SET_ERROR: '[ERROR]/SET_ERROR',
  REMOVE_ERROR: '[ERROR]/REMOVE_ERROR',
  CLEAR_ERRORS: '[ERROR]/CLEAR_ERRORS',
  ADD_MESSAGE: '[ERROR]/ADD_MESSAGE',
  CLEAR_MESSAGES: '[ERROR]/CLEAR_MESSAGES',

  LOGIN: '[SAGS/AUTH]/LOGIN',
  LOGOUT: '[SAGS/AUTH]/LOGOUT',
  SET_AUTH_DATA: '[SAGS/AUTH]/SET_AUTH_DATA',
  CLEAR_AUTH_DATA: '[SAGS/AUTH]/CLEAR_AUTH_DATA',
  RECONNECT_AUTH_DATA: '[SAGS/AUTH]/RECONNECT_AUTH_DATA',
  LOGOUT_BY_BLOCK: '[SAGS/AUTH]/LOGOUT_BY_BLOCK',
  CHECK_AUTH_DATA: '[SAGS/AUTH]/CHECK_AUTH_DATA',
  GET_CURRENT_USER: '[SAGS/AUTH]/GET_CURRENT_USER',
  SET_CURRENT_USER: '[SAGS/AUTH]/SET_CURRENT_USER',
  CLEAR_CURRENT_USER: '[SAGS/AUTH]/CLEAR_CURRENT_USER',
  CHECK_EXP_DATE: '[SAGS/AUTH]/CHECK_EXP_DATE',

  DOWNLOAD_FILE: '[SAGS/FILES]/DOWNLOAD_FILE',
  UPLOAD_FILE: '[SAGS/FILES]/UPLOAD_FILE',

  SET_ORDER: '[SAGS/ORDERS]/SET_ORDER',
  ORDERS_GET_LIST: '[SAGS/ORDERS]/ORDERS_GET_LIST',
  ORDERS_SET_LIST: '[SAGS/ORDERS]/ORDERS_SET_LIST',
  ORDERS_GET_ITEM: '[SAGS/ORDERS]/ORDERS_GET_ITEM',
  ORDERS_SET_ITEM: '[SAGS/ORDERS]/ORDERS_SET_ITEM',
  CREATE_ORDER: '[SAGS/ORDERS]/CREATE_ORDER',
  SEND_ORDER: '[SAGS/ORDERS]/SEND_ORDER',
  CLOSE_ORDER: '[SAGS/ORDERS]/CLOSE_ORDER',
  PRINT_ORDER_CODES: '[SAGS/ORDERS]/PRINT_ORDER_CODES',
  ORDER_SET_ITEM_ERROR: '[SAGS/ORDERS]/ORDER_SET_ITEM_ERROR',
  CLEAR_ORDER: '[SAGS/ORDERS]/CLEAR_ORDER',
  ORDERS_GET_RETRY_LIST: '[SAGS/ORDERS]/ORDERS_GET_RETRY_LIST',
  ORDERS_SET_RETRY_LIST: '[SAGS/ORDERS]/ORDERS_SET_RETRY_LIST',

  SET_REJECTION: '[SAGS/REJECTION]/SET_REJECTION',
  REJECTION_GET_LIST: '[SAGS/REJECTION]/REJECTION_GET_LIST',
  REJECTION_SET_LIST: '[SAGS/REJECTION]/REJECTION_SET_LIST',
  REJECTION_GET_ITEM: '[SAGS/REJECTION]/REJECTION_GET_ITEM',
  REJECTION_SET_ITEM: '[SAGS/REJECTION]/REJECTION_SET_ITEM',
  CREATE_REJECTION: '[SAGS/REJECTION]/CREATE_REJECTION',
  UPDATE_REJECTION: '[SAGS/REJECTION]/UPDATE_REJECTION',
  CLEAR_REJECTION: '[SAGS/REJECTION]/CLEAR_REJECTION',

  SET_MARKING: '[SAGS/MARKING]/SET_MARKING',
  MARKING_GET_LIST: '[SAGS/MARKING]/MARKING_GET_LIST',
  MARKING_SET_LIST: '[SAGS/MARKING]/MARKING_SET_LIST',
  MARKING_GET_ITEM: '[SAGS/MARKING]/MARKING_GET_ITEM',
  MARKING_SET_ITEM: '[SAGS/MARKING]/MARKING_SET_ITEM',
  MARKING_SET_ITEM_ERROR: '[SAGS/MARKING]/MARKING_SET_ITEM_ERROR',
  CREATE_MARKING: '[SAGS/MARKING]/CREATE_MARKING',
  UPDATE_MARKING: '[SAGS/MARKING]/CREATE_UPDATE',
  CLEAR_MARKING: '[SAGS/MARKING]/CLEAR_UPDATE',

  GET_SETTINGS: '[SAGS/MARKING]/GET_SETTINGS',
  SET_SETTINGS: '[SAGS/MARKING]/SET_SETTINGS',
  CLEAR_SETTINGS: '[SAGS/MARKING]/CLEAR_SETTINGS',

  SET_AGGREGATION: '[SAGS/REJECTION]/SET_AGGREGATION',
  AGGREGATION_GET_LIST: '[SAGS/AGGREGATION]/AGGREGATION_GET_LIST',
  AGGREGATION_SET_LIST: '[SAGS/AGGREGATION]/AGGREGATION_SET_LIST',
  AGGREGATION_GET_ITEM: '[SAGS/AGGREGATION]/AGGREGATION_GET_ITEM',
  AGGREGATION_SET_ITEM: '[SAGS/AGGREGATION]/AGGREGATION_SET_ITEM',
  CREATE_AGGREGATION: '[SAGS/AGGREGATION]/CREATE_AGGREGATION',
  UPDATE_AGGREGATION: '[SAGS/AGGREGATION]/UPDATE_AGGREGATION',
  CLEAR_AGGREGATION: '[SAGS/AGGREGATION]/CLEAR_AGGREGATION',
  SET_AGGREGATE: '[SAGS/AGGREGATION]/SET_AGGREGATE',
  CLEAR_AGGREGATE: '[SAGS/AGGREGATION]/CLEAR_AGGREGATE',

  SET_VALIDATION: '[SAGS/REJECTION]/SET_VALIDATION',
  VALIDATION_SET_ITEM: '[SAGS/VALIDATION]/VALIDATION_SET_ITEM',
  VALIDATION_GET_ITEM: '[SAGS/VALIDATION]/VALIDATION_GET_ITEM',
  CLEAR_VALIDATION: '[SAGS/VALIDATION]/CLEAR_VALIDATION',

  SET_REPORTS: '[SAGS/REPORTS]/SET_REPORTS',
  REPORT_GET_LIST: '[SAGS/REPORTS]/REPORT_GET_LIST',
  REPORT_SET_LIST: '[SAGS/REPORTS]/REPORT_SET_LIST',

  SET_DEVICES: '[SAGS/DEVICES]/SET_DEVICES',
  DEVICES_GET_LIST: '[SAGS/DEVICES]/DEVICES_GET_LIST',
  DEVICES_SET_LIST: '[SAGS/DEVICES]/DEVICES_SET_LIST',
  DEVICES_GET_ITEM: '[SAGS/DEVICES]/DEVICES_GET_ITEM',
  DEVICES_SET_ITEM: '[SAGS/DEVICES]/DEVICES_SET_ITEM',
  CREATE_DEVICES: '[SAGS/DEVICES]/CREATE_DEVICES',
  DELETE_DEVICE: '[SAGS/DEVICES]/DELETE_DEVICE',
  REMOVE_DEVICE_FROM_STORE: '[SAGS/DEVICES]/REMOVE_DEVICE_FROM_STORE',

  SET_STICKERS: '[SAGS/STICKERS]/SET_STICKERS',
  STICKERS_GET_LIST: '[SAGS/STICKERS]/STICKERS_GET_LIST',
  STICKERS_SET_LIST: '[SAGS/STICKERS]/STICKERS_SET_LIST',
  STICKERS_GET_ITEM: '[SAGS/STICKERS]/STICKERS_GET_ITEM',
  STICKERS_SET_ITEM: '[SAGS/STICKERS]/STICKERS_SET_ITEM',
  CREATE_STICKERS: '[SAGS/STICKERS]/CREATE_STICKERS',

  PARTICIPANT_GET_PROFILE: '[SAGS/PARTICIPANT]/PARTICIPANT_GET_PROFILE',
  PARTICIPANT_SET_PROFILE: '[SAGS/PARTICIPANT]/PARTICIPANT_SET_PROFILE',
  PARTICIPANT_CLEAR_PROFILE: '[SAGS/PARTICIPANT]/PARTICIPANT_CLEAR_PROFILE',
  PARTICIPANT_UPDATE_DISTRIBUTION: '[SAGS/PARTICIPANT]/PARTICIPANT_UPDATE_DISTRIBUTION',
  PARTICIPANT_SET_DISTRIBUTION: '[SAGS/PARTICIPANT]/PARTICIPANT_SET_DISTRIBUTION',

  SET_SP: '[SAGS/SERVICE_PROVIDER]/SET_SP',
  SP_GET_LIST: '[SAGS/SERVICE_PROVIDER]/SP_GET_LIST',
  SP_SET_LIST: '[SAGS/SERVICE_PROVIDER]/SP_SET_LIST',
  SP_GET_LIST_FOR_CONNECTION: '[SAGS/SERVICE_PROVIDER]/SP_GET_LIST_FOR_CONNECTION',
  SP_SET_LIST_FOR_CONNECTION: '[SAGS/SERVICE_PROVIDER]/SP_SET_LIST_FOR_CONNECTION',
  DELETE_SP_CONNECTIONS: '[SAGS/SERVICE_PROVIDER]/DELETE_SP_CONNECTIONS',
  CREATE_SP_CONNECTIONS: '[SAGS/SERVICE_PROVIDER]/CREATE_SP_CONNECTIONS',
  CLEAR_SP_CONNECTIONS: '[SAGS/SERVICE_PROVIDER]/CLEAR_SP_CONNECTIONS',

  SET_SD: '[SAGS/SERVICE_DISTRIBUTIONS]/SET_SD',
  SD_GET_LIST: '[SAGS/SERVICE_DISTRIBUTIONS]/SD_GET_LIST',
  SD_SET_LIST: '[SAGS/SERVICE_DISTRIBUTIONS]/SD_SET_LIST',
  SD_GET_ITEM: '[SAGS/SERVICE_DISTRIBUTIONS]/SD_GET_ITEM',
  SD_SET_ITEM: '[SAGS/SERVICE_DISTRIBUTIONS]/SD_SET_ITEM',
  CREATE_SD: '[SAGS/SERVICE_DISTRIBUTIONS]/CREATE_SD',
  UPDATE_SD: '[SAGS/SERVICE_DISTRIBUTIONS]/UPDATE_SD',
  CLEAR_SD: '[SAGS/SERVICE_DISTRIBUTIONS]/CLEAR_SD',
};

export default types;
