/* eslint-disable */
import {
  ButtonBase,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
  withStyles,
} from '@material-ui/core';
import cn from 'classnames';
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

const MenuList = withStyles({
  root: {
    boxShadow: 'inset 0 1px 0 #f7f7f7, inset 0 -1px 0 #f7f7f7',
  },
})(MuiMenuList);

const Menu = withStyles((theme) => ({
  paper: {
    boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.125)',
    borderRadius: '0px 0px 4px 4px',

    '&::-webkit-scrollbar': {
      width: 4,
    },

    '&::-webkit-scrollbar-track': {
      background: '#f2f2f2',
    },

    '&::-webkit-scrollbar-thumb': {
      width: 2,
      borderRadius: 2,
      background: theme.palette.text.primary,
    },
  },
  list: {
    paddingTop: 8,
    paddingBottom: 8,
  },
}))(MuiMenu);

const SubMenuItem = withStyles((theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 16 / 14,
    padding: '12px 16px',

    '&:hover, &:focus': {
      background: '#f7f7f7',
    },

    '&$selected, &$selected:hover': {
      background: theme.palette.primary.main,
    },
  },
  selected: {},
}))(MuiMenuItem);

const MenuIcon = withStyles((theme) => ({
  root: {
    alignItems: 'center',
    border: '2px solid transparent',
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'flex',
    flexShrink: 0,
    height: 48,
    justifyContent: 'center',
    marginRight: 16,
    width: 48,
    '& path': {
      fill: '#888888'
    },
    '&$active': {
      borderColor: theme.palette.primary.iconColor,
    },

    '&$activatedMenu': {
      background: theme.palette.primary.main,
      '& path': {
        fill: '#ffffff'
      },
    },
  },
  active: {},
  activatedMenu: {},
}))(({ active, activatedMenu, classes, className, children }) => {
  return (
    <div
      className={cn(classes.root, className, {
        [classes.active]: active,
        [classes.activatedMenu]: activatedMenu,
      })}
    >
      {children}
    </div>
  );
});

export const BaseMenuButton = withStyles({
  root: {
    height: 64,
    minWidth: 80,
    padding: 16,
  },
})(ButtonBase);

const MenuButton = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: 14,
    fontWeight: 600,
    height: 64,
    boxSizing: 'border-box',
    paddingTop: 8,
    paddingBottom: 8,
    justifyContent: 'flex-start',
    lineHeight: 16 / 14,
    transition: theme.transitions.create(['background']),
    whiteSpace: 'nowrap',
    width: '100%',
    color: theme.palette.text.primary,

    '&:hover': {
      background: '#F7F7F7',
      '& div': {
        background: theme.palette.primary.iconColor,
        color: theme.palette.primary.iconColorHover,
        '& path': {
          fill: '#ffffff'
        },
      },
    },
  },
}))(BaseMenuButton);

const MenuItem = memo(
  ({ icon, items = [], expanded, active, children, ...rest }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const shouldRenderMenu = useMemo(() => items && items.length, [items]);

    useEffect(() => {
      if (!expanded) {
        setAnchorEl(null);
      }
    }, [expanded]);

    const handleClick = useCallback(
      (event) => {
        event.persist();
        if (shouldRenderMenu) {
          setAnchorEl(expanded && anchorEl ? null : event.currentTarget);
        } else {
          if (typeof rest.onClick === 'function') {
            rest.onClick(event);
          }
        }
      },
      [shouldRenderMenu, expanded, anchorEl],
    );

    const handleClose = useCallback(() => {
      setAnchorEl(null);
    }, []);

    const renderMenuItem = useCallback(
      ({ label, onClick, icon, ...rest }) => (
        <SubMenuItem
          key={label}
          disableRipple
          disableTouchRipple
          onClick={(event) => {
            if (typeof onClick === 'function') {
              onClick(event);
            }

            handleClose();
          }}
          style={{ paddingLeft: expanded ? 80 : undefined }}
          {...rest}
        >
          {icon ? <span style={{ marginRight: 8 }}>{icon}</span> : null}
          {label}
        </SubMenuItem>
      ),
      [expanded],
    );

    const renderMenuItems = useCallback(() => {
      const itemsForRender = expanded
        ? items
        : [
          <div
            style={{
              fontSize: 14,
              lineHeight: 16 / 14,
              padding: 16,
              fontWeight: 600,
              outline: 0,
            }}
            key={-1}
          >
            {children}
          </div>,
          ...items,
        ];
      return itemsForRender.map((item) => {
        if (React.isValidElement(item)) {
          return item;
        }

        return renderMenuItem(item);
      });
    }, [items, expanded]);

    const renderMenu = useCallback(() => {
      return expanded && anchorEl ? (
        <MenuList>{renderMenuItems()}</MenuList>
      ) : (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          disableAutoFocusItem
          getContentAnchorEl={null}
          marginThreshold={0}
          onClose={handleClose}
          open={Boolean(anchorEl)}
          transitionDuration={0}
        >
          {renderMenuItems()}
        </Menu>
      );
    }, [items, anchorEl, expanded]);

    return (
      <Fragment>
        <MenuButton
          disableRipple
          disableTouchRipple
          onClick={handleClick}
          {...rest}
        >
          {icon && (
            <MenuIcon activatedMenu={anchorEl} active={active}>
              {icon}
            </MenuIcon>
          )}
          {children}
        </MenuButton>
        {shouldRenderMenu ? renderMenu() : null}
      </Fragment>
    );
  },
);

export default MenuItem;
