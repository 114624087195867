import moment from 'moment';
import { get } from 'lodash';
import types from '../types';
import { PRODUCT_GROUPS_NAMES } from '../../constants';
import { AGGREGATION_STATUSES } from '../../pages/marking/constants';
import i18n from '../../i18n';

const initialState = {
  list: [],
  item: {},
  id: null,
  totalElements: 0,
  filter: {},
  pagination: { page: 0, size: 10 },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AGGREGATION_SET_LIST:
      return {
        ...state,
        totalElements: action.payload.totalElements,
        list: action.payload.content,
      };
    case types.AGGREGATION_SET_ITEM:
      return {
        ...state,
        item: parseItem(action.payload),
      };
    case types.SET_AGGREGATION:
      return {
        ...state,
        ...action.payload,
      };
    case types.CLEAR_AGGREGATION:
      return {
        ...state,
        item: {},
      };
    case types.SET_AGGREGATE:
      return {
        ...state,
        id: action.payload,
      };
    case types.CLEAR_AGGREGATE:
      return {
        ...state,
        id: null,
      };
    default:
      return state;
  }
};

const parseItem = (data) => {
  const aggregationUnits = get(data, 'json.aggregationUnits', [])[0];
  return {
    ...data,
    pgName: i18n.t(PRODUCT_GROUPS_NAMES[data.pg]),
    statusName: i18n.t(AGGREGATION_STATUSES[data.status]),
    products: get(data, 'json.aggregationUnits', []),
    createdTimestamp: moment(data.createdTimestamp).format('DD.MM.YYYY HH:mm'),
    original: {
      pg: data.pg,
      ...data.json,
      unitSerialNumber: get(aggregationUnits, 'unitSerialNumber', '—'),
      aggregationUnitCapacity: get(aggregationUnits, 'aggregationUnitCapacity', '—'),
      aggregatedItemsCount: get(aggregationUnits, 'aggregatedItemsCount', '—'),
      sntins: get(aggregationUnits, 'sntins', []),
    },
  };
};

export default reducer;
