import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  authPage: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.bgColor,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bg: {
    background: theme.palette.primary.main,
    height: '35%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '40px 40px 60px',
    boxSizing: 'border-box',
  },
  header: {
    zIndex: 11,
    color: theme.palette.text.white,
    textTransform: 'uppercase',
  },
  logo: {
    zIndex: 11,
    height: 150,
    '& img': {
      height: '100%',
    },
  },
  form: {
    width: 450,
    padding: '40px 25px',
    marginTop: '-40px',
    boxShadow: '2px 0px 15px rgba(0, 0, 0, 0.05)',
    position: 'relative',
    zIndex: 11,
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  actions: {
    marginTop: 15,
  },
  lang: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
}));
