import { put, call } from 'redux-saga/effects';
import { pick } from 'lodash';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { addError } from '../actions/errors';
import {
  setItem, setList, setError, setRetryCodeList,
} from '../actions/orders';
import { history } from '../../store';
import { routes } from '../../routes';
import { CONTENT_TYPES } from '../../helpers/http';

export function* loadOrderListSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.orders.list(action.payload);
    yield put(setList({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadOrderItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.orders.item(action.payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(setError(true));
    yield put(addError(err));
  }
}

export function* closeOrderSaga({ payload }) {
  const { orderId } = payload.query;
  try {
    yield put(setIsLoading(true));
    yield API.rest.order.close(payload.query);
    if (payload.buffer) {
      yield call(loadOrderItemSaga, { payload: orderId });
    } else {
      history.push(routes.orders());
    }
    yield put(setIsLoading(false));
    payload.onFinish();
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* sendOrderSaga({ payload }) {
  const { filter } = payload;
  try {
    yield put(setIsLoading(true));
    yield API.rest.orders.patch({ id: payload.id, body: {} });
    yield call(loadOrderListSaga, { payload: filter });
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* printSaga(query, payload, packId) {
  const printQuery = {
    ...query,
    format: payload.format,
    packId,
  };
  let format = 'csv';
  if (payload.format === 'PDF') {
    printQuery.position = payload.position;
    format = 'pdf';
  }
  yield API.rest.codes.print(printQuery, `Order_${payload.orderId}.${format}`, CONTENT_TYPES.json);
}

export function* printOrderCodesSaga({ payload }) {
  try {
    const { isRetry } = payload;
    let json;
    if (!isRetry) {
      json = yield API.rest.codes.list(pick(payload, ['orderId', 'gtin', 'quantity', 'lastPackId', 'pdfStyle']));
    }
    const packId = isRetry ? payload.lastPackId : json.packId;
    yield call(
      printSaga,
      pick(payload, ['orderId', 'gtin', 'quantity', 'pdfStyle']),
      payload,
      packId,
    );
    yield call(getRetryCodeListSaga, { payload: { orderId: payload.orderId } });
    if (!isRetry) {
      const order = yield API.rest.orders.item(payload.orderId);
      yield put(setItem({ ...order }));
    }
    payload.onFinish();
  } catch (err) {
    yield put(addError(err));
    payload.onFinish();
  }
}

export function* createOrderSaga({ payload }) {
  try {
    // eslint-disable-next-line no-unreachable
    yield put(setIsLoading(true));
    const data = { ...payload };
    const pg = data.productGroup;
    delete data.productGroup;
    yield API.rest.orders.create(data, { pg });
    yield put(setIsLoading(false));
    yield history.push(routes.orders());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* getRetryCodeListSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.codes.retryList({ ...payload });
    yield put(setRetryCodeList({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}
