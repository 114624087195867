import types from '../types';
import { getTokenData } from '../../utils/utils';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_CURRENT_USER:
      return {};
    case types.SET_CURRENT_USER:
      return {
        ...getTokenData(action.payload, 'access_token'),
      };
    default:
      return state;
  }
};

export default reducer;
