import { put } from 'redux-saga/effects';
import moment from 'moment';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { addError } from '../actions/errors';
import { setItem, setList } from '../actions/rejections';
import { history } from '../../store';
import { routes } from '../../routes';

export function* loadRejectionsListSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.rejections.list(action.payload);
    yield put(setList({ ...mockListData(json) }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* loadRejectionItemSaga(action) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.reports.item(action.payload);
    yield put(setItem({ ...json }));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* createRejectionsSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.dropout(payload.data, payload.query);
    yield put(setIsLoading(false));
    yield history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export function* updateDropoutSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.reports.dropoutUpdate(payload.data, payload.query, payload.reportId);
    yield put(setIsLoading(false));
    yield history.push(routes.documents());
  } catch (err) {
    yield put(setIsLoading(false));
    yield put(addError(err));
  }
}

export const mockListData = (list) => ({
  content: list.map(
    (item, idx) => ({
      id: item.id,
      productGroupType: idx % 2 === 0 ? 'medicine' : 'tobacco',
      creationDate: moment().format('DD.MM.YYYY'),
      result: item.title,
    }),
  ),
  totalElements: list.length,
});
